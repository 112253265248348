import { FakedCodelists } from '@administration/codelists/codelists.interface';
import { Codelists } from '@administration/codelists/codelists.service';
import { Component, Input } from '@angular/core';
import _ from 'lodash';

@Component({
    selector: 'app-flag-icon',
    template: `
        @if (code && codelist) {
            <span
                class="shadow-[0px_0px_3px_3px_rgba(0,0,0,0.05)] fi fi-{{ getCountry() }} {{ square ? 'fis' : '' }}"
                kendoTooltip
                title="{{ code | codelist: codelist : false | async | translate }}"
                [ngClass]="{ 'box-shadow tight': floating }"></span>
        } @else {
            <span
                class="shadow-[0px_0px_3px_3px_rgba(0,0,0,0.05)] fi fi-{{ getCountry() }} {{ square ? 'fis' : '' }}"
                [ngClass]="{ 'box-shadow tight': floating }"></span>
        }
    `
})
export class FlagIconComponent {
    @Input() code: string;
    @Input() codelist: Codelists | FakedCodelists;
    @Input() square = false;
    @Input() floating = false;

    getCountry() {
        return this.code ? _.toLower(this.code.substring(0, 2)) : 'xx';
    }
}
