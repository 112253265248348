import { Injectable } from '@angular/core';
import { RememberStateService } from './remember-state.service';

@Injectable({
    providedIn: 'root'
})
export class DarkModeService {
    private isDarkMode: boolean;

    constructor(private rememberStateService: RememberStateService) {
        // Initialize the dark mode state from the remember state service
        this.isDarkMode = this.rememberStateService.get('darkMode') ?? false;
    }

    set(isEnabled: boolean): void {
        this.isDarkMode = isEnabled;
        // Save the state in remember state service
        this.rememberStateService.set('darkMode', isEnabled);
    }

    toggle(): void {
        this.set(!this.isDarkMode);
    }

    get(): boolean {
        return this.isDarkMode;
    }
}
