import { Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { IconKey } from '@common/classes/icons';
import { ViewMode } from '@common/models/view-mode';
import { NAVIGATION_FLAT, NavItem } from 'app/navigation';
import { filter } from 'rxjs';

export interface Breadcrumb {
    route?: string;
    title: string;
    icon: IconKey;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private router = inject(Router);
    navigation = NAVIGATION_FLAT;

    navigationEndEvent = toSignal<NavigationEnd>(
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
    );

    currentNavItem = computed(() => {
        if (!this.navigationEndEvent()?.url) return null;
        return this.getCurrentNavItem(this.navigationEndEvent()?.url);
    });

    breadcrumbs = computed(() => {
        if (!this.navigationEndEvent()?.url) return [];
        return this.createBreadcrumbs(this.navigationEndEvent()?.url);
    });

    private getCurrentNavItems(url: string, navItems: NavItem[]): NavItem[] {
        const currentItems: NavItem[] = [];
        const urlParts = url.split('/').filter(Boolean);

        const findMatchingItems = (items: NavItem[], depth: number = 0): boolean => {
            for (const item of items) {
                // Add the current item to the breadcrumb path
                currentItems[depth] = item;

                if (item.path && this.isUrlMatch(item.path, urlParts)) {
                    // Found a match: trim any excess items and stop searching
                    currentItems.splice(depth + 1);
                    return true;
                }

                if (item.children && findMatchingItems(item.children, depth + 1)) {
                    // Match found in children: propagate the success upwards
                    return true;
                }
            }

            // No match at this level: remove the last item and continue searching
            currentItems.pop();
            return false;
        };

        // Initiate the recursive search through the navigation tree
        findMatchingItems(navItems);

        // Return the full breadcrumb path, including items without direct URLs
        return currentItems;
    }

    private isUrlMatch(itemPath: string, urlParts: string[]): boolean {
        const itemParts = itemPath.split('/').filter(Boolean);
        return itemParts.every((part, index) => part === urlParts[index]);
    }

    private getCurrentNavItem(url: string) {
        const currentNavItems = this.getCurrentNavItems(url, this.navigation);
        if (!currentNavItems.length) return null;
        return currentNavItems.at(-1);
    }

    private createBreadcrumbs(url: string) {
        const currentNavItems = this.getCurrentNavItems(url, this.navigation);

        if (!currentNavItems.length) return [];

        const newBreadcrumbs: Breadcrumb[] = currentNavItems.map((navItem) => ({
            icon: navItem.icon,
            title: navItem.title,
            route: navItem.path
        }));

        // The ViewModes do not have entries in the navigation, so we need to add them manually
        if (Object.values(ViewMode).some((substring) => url.includes(substring))) {
            newBreadcrumbs.push({
                icon: currentNavItems.at(-1).icon,
                title: currentNavItems.at(-1).path.split('/').pop()
            });
        }

        return newBreadcrumbs;
    }
}
