<app-input-label [label]="label" [labelPosition]="labelPosition" [for]="id" [isRequired]="isRequired">
    @if (isEditMode) {
        @if (isMultiple) {
            <kendo-multiselect
                [ngClass]="generateInputStyle()"
                [id]="id"
                [title]="title | translate"
                [textField]="textField"
                [valueField]="valueField"
                [fillMode]="'flat'"
                [valuePrimitive]="valuePrimitive"
                [data]="filteredOptions()"
                [filterable]="true"
                [clearButton]="isRequired !== true || isClearable"
                [readonly]="isDisabled"
                [popupSettings]="popupSettings"
                [(ngModel)]="value"
                (valueChange)="onValueChange($event)"
                (inputFocus)="onFocusIn()"
                (inputBlur)="onFocusOut()"
                (filterChange)="filterTerm.set($event)">
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                    <div class="p-1">
                        <label class="k-checkbox-label">{{ dataItem.label | translate }}</label>
                    </div>
                </ng-template>
            </kendo-multiselect>
        } @else if (isFilterable) {
            <kendo-combobox
                data-container="body"
                [ngClass]="generateInputStyle()"
                [id]="id"
                [title]="title | translate"
                [textField]="textField"
                [valueField]="valueField"
                [valuePrimitive]="valuePrimitive"
                [data]="filteredOptions()"
                [filterable]="true"
                [clearButton]="!isRequired || isClearable"
                [disabled]="isDisabled"
                [readonly]="isDisabled"
                [popupSettings]="popupSettings"
                [(ngModel)]="value"
                (valueChange)="onValueChange($event)"
                (inputFocus)="onFocusIn()"
                (inputBlur)="onFocusOut()"
                (filterChange)="filterTerm.set($event)" />
        } @else {
            <kendo-dropdownlist
                [ngClass]="generateInputStyle()"
                [id]="id"
                [title]="title | translate"
                [data]="options()"
                [textField]="textField"
                [valueField]="valueField"
                [valuePrimitive]="valuePrimitive"
                [popupSettings]="popupSettings"
                [defaultItem]="defaultItem"
                [disabled]="isDisabled"
                [readonly]="isDisabled"
                [required]="isRequired"
                [(ngModel)]="value"
                (inputFocus)="onFocusIn()"
                (inputBlur)="onFocusOut()"
                (valueChange)="onValueChange($event)" />
        }
        <app-input-error [error]="error()?.[0]" />
    } @else {
        <div class="pb-1 pt-1.5">
            {{ formatedSelectedOptions() }}
        </div>
    }
</app-input-label>
