<kendo-drawer-container class="pointer-events-none fixed right-0 top-0 z-[101] h-full">
    <kendo-drawer
        class="pointer-events-auto"
        #drawer
        mode="overlay"
        position="end"
        [width]="300"
        (keyup.enter)="search.emit(); drawer.toggle()"
        (keyup.esc)="drawer.toggle()">
        <ng-template kendoDrawerTemplate>
            <div class="relative flex h-screen flex-col gap-3 bg-background p-4 text-foreground">
                <div class="flex items-center justify-between">
                    <div class="flex items-center justify-start gap-2">
                        <h2 class="text-xl">
                            {{ title | translate }}
                        </h2>
                    </div>
                    <app-button size="lg" variant="link" icon="faSolidXmark" (clicked)="drawer.toggle()" />
                </div>
                <ng-content />
                <div *ngIf="showFilterButtons" class="flex justify-end gap-2 p-1">
                    <app-button variant="secondary" icon="faSolidFilterCircleXmark" (click)="clearFilter.emit()">
                        {{ 'Clear' | translate }}
                    </app-button>
                    <app-button
                        *ngIf="showResetButton"
                        variant="info"
                        icon="faSolidArrowRotateLeft"
                        (click)="resetOptions.emit(); clearFilter.emit(); search.emit()">
                        {{ 'Reset' | translate }}
                    </app-button>
                    <app-button
                        variant="primary"
                        icon="faSolidMagnifyingGlass"
                        translate
                        (click)="search.emit(); drawer.toggle()">
                        {{ 'Search' | translate }}
                    </app-button>
                </div>
            </div>
        </ng-template>
    </kendo-drawer>
    <kendo-drawer-content *ngIf="showToggleButton" class="pointer-events-auto">
        <app-button
            class="fixed right-4 top-36 z-10 h-12 w-12 rounded-full bg-primary text-white"
            variant="link"
            icon="faSolidFilter"
            (clicked)="drawer.toggle()" />
    </kendo-drawer-content>
</kendo-drawer-container>
