import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-loader',
    template: `
        @if (isBusy) {
            <div class="loader"></div>
        } @else {
            <ng-container *ngTemplateOutlet="detailRef"></ng-container>
        }
    `,
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    @ContentChild(TemplateRef, { static: true }) detailRef;
    @Input() isBusy = true;
}
