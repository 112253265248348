import { Injectable, Injector } from '@angular/core';
import { FormProperty } from '@common/components/app-control/app-control.component';
import { DialogFormComponent, DialogFormOptions } from '@common/components/dialog-form/dialog-form.component';
import {
    DialogCloseResult,
    DialogRef,
    DialogResult,
    DialogSettings,
    DialogService as KendoDialogService
} from '@progress/kendo-angular-dialog';
import { firstValueFrom } from 'rxjs';
import { DialogConfirmComponent, DialogConfirmOptions } from '../components/dialog-confirm';

@Injectable({ providedIn: 'root' })
export class DialogService {
    constructor(
        private kendoDialogService: KendoDialogService,
        private injector: Injector
    ) {}

    async confirm({
        options,
        dialogSettings
    }: {
        options: DialogConfirmOptions;
        dialogSettings?: DialogSettings;
    }): Promise<boolean> {
        const dialog = this.kendoDialogService.open({
            content: DialogConfirmComponent,
            width: 420,
            minHeight: 250,
            ...dialogSettings
        });
        const dialogInstance = dialog.content.instance;

        dialogInstance.initialize(options);

        const result = await firstValueFrom(dialog.result);
        return result instanceof DialogCloseResult ? false : !!result;
    }

    async open({
        template,
        dialogSettings
    }: {
        template: {
            content: any;
            createdCallback?: (dialogRef: DialogRef) => void;
            data?: { [key: string]: any };
        };
        dialogSettings?: DialogSettings;
    }): Promise<DialogResult> {
        const dialog = this.kendoDialogService.open({
            content: template.content,
            width: 420,
            minHeight: 250,
            ...dialogSettings
        });
        const dialogInstance = dialog.content.instance;
        if (template.data) {
            if (typeof dialogInstance.initialize === 'function') {
                dialogInstance.initialize(template.data, this.injector);
            } else {
                Object.assign(dialogInstance, template.data);
            }
        }

        if (template.createdCallback) template.createdCallback(dialogInstance);

        const result = await firstValueFrom(dialog.result);
        return result instanceof DialogCloseResult ? false : result;
    }

    async form({
        options,
        dialogSettings
    }: {
        options: DialogFormOptions;
        dialogSettings?: DialogSettings;
    }): Promise<any> {
        const dialog = this.kendoDialogService.open({
            content: DialogFormComponent,
            width: 420,
            minHeight: 250,
            ...dialogSettings
        });
        const dialogInstance = dialog.content.instance;

        dialogInstance.initialize(options, this.injector);

        const result = await firstValueFrom(dialog.result);
        return result instanceof DialogCloseResult ? false : result;
    }

    /**
     * Function for initializing FormProperty model for {@link DialogService.form}, for component {@link DialogFormComponent} the property: {@link DialogFormComponent.model}
     * @param properties - FormProperty array used to display inputs on {@link DialogFormComponent}
     * @param item - Item object used to fill the initial values of the properties
     * @constant props - Copied array of properties without reference {@link props}
     */
    initializeFormProperties(properties: FormProperty[], item: {}): FormProperty[] {
        const props = properties.map((prop) => ({ ...prop }));
        if (item) props.forEach((prop) => (prop.initialValue = item[prop.name]));
        return props;
    }

    /**
     * Function for canConfirm predicate on {@link DialogService.form}, for component {@link DialogFormComponent} the property: {@link DialogFormComponent.canConfirmPredicate}
     * @param formModel - The model of the form properties
     * @param formProps - String array of properties (name field of FormProperty), to be excluded/included for filledCondition
     * @param includeProps - Comparison property for filledCondition, to filter out properties from formModel keys
     * @param model - The model that is being edited
     * @constant filledCondition - The condition to check if all required properties of formModel are filled {@link filledCondition}
     * @constant differentCondition - The condition to check if any properties of formModel are different from model {@link differentCondition}
     */
    canConfirmForm(formModel: any, formProps: string[], includeProps: boolean = true, model: any = null): boolean {
        const props = Object.keys(formModel);
        const filledCondition = props
            .filter((key) => formProps.includes(key) === includeProps)
            .every((prop) => !!formModel[prop]);
        const differentCondition = props.some((prop) => formModel[prop] !== model?.[prop]);

        return filledCondition && differentCondition;
    }
}
