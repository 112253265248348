import { animate, state, style, transition, trigger } from '@angular/animations';

export const fade = trigger('fade', [
    state(
        'show',
        style({
            opacity: 1,
            visibility: 'visible'
        })
    ),
    state(
        'hide',
        style({
            opacity: 0,
            visibility: 'hidden'
        })
    ),
    transition('show => hide', [animate('0ms ease-out')]),
    transition('hide => show', [animate('300ms 100ms ease-in')])
]);
