<div class="flex flex-row">
    <kendo-label
        class="flex items-center"
        *ngFor="let option of options; let i = index"
        [ngClass]="{
            'flex-col-reverse': labelPosition === 'top',
            'flex-col': labelPosition === 'bottom',
            'flex-row-reverse': labelPosition === 'left',
            'flex-row': labelPosition === 'right'
        }"
        [class.mr-10]="labelPosition === 'top' || labelPosition === 'bottom' || labelPosition === 'right'"
        [class.ml-10]="labelPosition === 'left' && i !== 0">
        <input
            type="radio"
            kendoRadioButton
            [value]="option"
            [class.ml-1]="labelPosition === 'left'"
            [class.mr-1]="labelPosition === 'right'"
            [(ngModel)]="selectedOption"
            (change)="selectionChange.emit(selectedOption)" />
        <span>{{ option }}</span>
    </kendo-label>
</div>
