@if (items.length) {
    <div class="relative">
        <button class="group" kendoButton translate [fillMode]="'flat'" (click)="onGroupClick()">
            {{ label | translate }}
            <ng-icon
                class="rotate-icon opacity-70 transition-opacity group-hover:opacity-100"
                name="faSolidSortDown"
                [class.rotate]="isOpen" />
        </button>
        @if (isOpen) {
            <div class="absolute top-11 z-10 divide-y rounded-lg bg-card shadow-lg" (clickOutside)="onOutsideClick()">
                <ul class="flex flex-col gap-1 py-2">
                    @for (item of items; track $index) {
                        <li>
                            <button
                                class="flex w-full justify-start"
                                kendoButton
                                fillMode="flat"
                                title="{{ item.label | translate }}"
                                [disabled]="item.isDisabled"
                                (click)="item.onClick()">
                                <div class="flex items-center gap-2">
                                    <ng-icon [name]="item.icon" />
                                    {{ item.label | translate }}
                                </div>
                            </button>
                        </li>
                    }
                </ul>
            </div>
        }
    </div>
}
