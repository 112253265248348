import { Component } from '@angular/core';

@Component({
    selector: 'app-authorization-failed',
    template: `
        <div class="row mt-5 text-center">
            <div class="col"></div>
            <div class="col-auto">
                <h2>
                    <ng-icon class="mr-2 text-danger" size="2x" name="faSolidCircleExclamation" />
                    <br />
                    <span translate>401 - Unauthorized</span>
                    <br />
                    <a class="btn btn-link" translate [routerLink]="['/']">Back Home</a>
                </h2>
            </div>
            <div class="col"></div>
        </div>
    `
})
export class UnauthorizedComponent {}
