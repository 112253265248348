import { formatDate } from '@angular/common';
import { AfterContentChecked, Component, ViewEncapsulation } from '@angular/core';
import { NoticeBoardPermissions } from '@common/classes/permissions';
import { ActionBarGroup } from '@common/components/action-bar/action-bar.interface';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { AttachmentResponse } from '@common/interfaces/attachment.interface';
import { CommonService } from '@common/services/common.service';
import { environment } from '@environments/environment.base';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { Notification } from 'app/notice-board/models/notification.interface';
import _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { BaseNotificationListComponent } from '../base-notification-list.component';

@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
    providers: [CommonService],
    encapsulation: ViewEncapsulation.None
})
export class NotificationListComponent extends BaseNotificationListComponent implements AfterContentChecked {
    currentUser = this.commonService.userService.currentUserSubject.value;
    queryName = this.currentUser?.hasPermission(NoticeBoardPermissions.View.List)
        ? 'Notifications'
        : 'UserNotifications';

    actionBar: ActionBarGroup[] = [
        {
            label: 'Actions',
            items: [
                {
                    label: 'New',
                    icon: 'faSolidPlus',
                    isVisible: () => this.isModerator,
                    onClick: () => this.createNew()
                },
                {
                    label: 'Mark all as read',
                    icon: 'faSolidEnvelopeCircleCheck',
                    isDisabled: () => this.allRead,
                    onClick: () => this.markNotificationsAsSeen(null, true)
                }
            ]
        }
    ];

    /**
        Value must match the NotificationTab enum in the backend.
        Index must match the order of the tabs. We use this in order to get the value of the tab in onTabSelect method.
        We can't use the title because it can be translated and we don't want to rely on that.
     **/
    tabs: { index: number; title: string; value: string }[] = [
        { index: 0, title: 'All', value: 'All' },
        { index: 1, title: 'Unread', value: 'Unread' },
        { index: 2, title: 'Important', value: 'Important' }
    ];

    currentTab = 'All';

    expandedStates: { [id: string]: boolean } = {};

    subtitle: string;

    constructor(commonService: CommonService) {
        super(commonService);
        if (this.canSeeInactive) this.tabs.push({ index: 3, title: 'Inactive', value: 'Inactive' });
        this.handleRouteState();
    }

    get isModerator(): boolean {
        return this.currentUser?.hasPermission(NoticeBoardPermissions.Action);
    }

    get canSeeList(): boolean {
        return this.currentUser?.hasPermission(NoticeBoardPermissions.View.List);
    }

    get canSeeInactive(): boolean {
        return this.currentUser?.hasPermission(NoticeBoardPermissions.View.Deactivated);
    }

    get allRead(): boolean {
        return this.data?.every((notification) => notification.seen);
    }

    get nrOfActiveFilters(): number {
        const clone = _.clone(this.getFilter());
        delete clone.skip;
        delete clone.take;
        return (
            Object.values(clone).filter(
                (x) => ![null, undefined].includes(x) && !this.tabs.map((tab) => tab.value).includes(x as string)
            )?.length || 0
        );
    }

    createNew = () => this.commonService.router.navigate([`/notice-board/notification/create/`]);

    ngAfterContentChecked(): void {
        if (!this.currentTab) {
            this.currentTab = this.filter.tab || 'All';
        }
        this.subtitle =
            this.data.filter((notification) => !notification.seen).length > 0
                ? `${this.data.filter((notification) => !notification.seen).length} unread`
                : '';
    }

    loadMore(): void {
        if (this.data.length >= this.total) {
            return;
        } else if (this.data.length + this.loadSize >= this.total) {
            this.filter = { ...this.filter, skip: this.data.length, take: this.total - this.data.length };
        } else {
            this.filter = { ...this.filter, skip: this.data.length, take: this.loadSize };
        }

        this.saveFilter();
        this.search();
    }

    navigateToNotification(notificationId: number) {
        this.commonService.router.navigate(['/notice-board/notification/edit', notificationId]);
        this.markNotificationsAsSeen(notificationId);
    }

    async deactivateNotification(notification: Notification) {
        let res = await this.commonService.dialogService.confirm({
            options: {
                title: 'Deactivate Notification',
                message: 'Are you sure you want to deactivate this notification?'
            }
        });
        if (!res) return;
        res = await this.commonService.queryService.command('SaveNotification', {
            id: notification.id,
            saveModel: { ...notification, active: false },
            action: 'edit'
        });
        if (!res) return;
        this.resetData();
        this.search();
        this.commonService.toastrNotificationService.show({
            type: 'success',
            message: 'Save successful'
        });
    }

    expandedChange(isExpanded: boolean, notification: Notification) {
        this.expandedStates[notification.id] = isExpanded;
        if (isExpanded && notification) {
            if (!notification.seen && notification.active) this.markNotificationsAsSeen(notification.id);
            notification.seen = true;
        }
    }

    displayFirstSentence(text: string): string {
        if (!text) return '';
        let firstSentence = text.match(/.*?[.!?]/)?.[0];
        if (!firstSentence) {
            const closingTagIndex = text.indexOf('</p>');
            if (closingTagIndex !== -1) {
                firstSentence = text.substring(0, closingTagIndex + '</p>'.length);
            }
        }
        return firstSentence && firstSentence?.length > 200 ? `${firstSentence.substring(0, 200)}...` : firstSentence;
    }

    onTabSelect(event: SelectEvent): void {
        this.currentTab = this.tabs.find((tab) => tab.index === event.index)?.value;
        this.getDefaultFilter();
        // Why reset skip and pageSize? - Because we want to start from the beginning when changing tabs
        this.resetData();
        this.filter = { ...this.filter, tab: this.currentTab, skip: 0, take: this.loadSize };
        this.search();
    }

    markNotificationsAsSeen(notificationId?: number, markAll: boolean = false) {
        this.commonService.queryService
            .getCommandMutation()
            .mutateAsync({
                command: 'MarkNotificationsAsSeen',
                data: { notificationId, markAll }
            })
            .then(() => {
                if (markAll) {
                    this.search();
                }
            });
    }

    async previewAttachment(event: Event, fileId: number) {
        event.preventDefault();
        const attachment: AttachmentResponse = await firstValueFrom(
            this.commonService.http.get<AttachmentResponse>(
                `${environment.settings.appControl.fileDownloadUrl}?id=${fileId}`
            )
        );
        UploadActionButtonsComponent.preview(attachment.name, attachment.content);
    }

    private handleRouteState(): void {
        const queryParams = this.commonService.router.routerState.snapshot.root.queryParams;
        if (queryParams?.tab) {
            this.currentTab = queryParams.tab;
            this.filter = { ...this.filter, tab: this.currentTab };
            this.search(true);
            // // clear the params
            this.commonService.router.navigate([], {
                queryParams: { tab: null },
                queryParamsHandling: 'merge'
            });
        } else {
            this.currentTab = this.filter?.tab || 'All';
            this.search();
        }
    }

    getTimeLimitTooltip(notification: Notification): string {
        if (notification.validFromDate) {
            return `${this.commonService.translateService.instant('From')} ${formatDate(notification.validFromDate, 'dd. MM. YYYY mm:hh', 'en-US')}`;
        }
        if (notification.validUntilDate) {
            return `${this.commonService.translateService.instant('Until')} ${formatDate(notification.validUntilDate, 'dd. MM. YYYY mm:hh', 'en-US')}`;
        }
        if (notification.validFromDate && notification.validUntilDate) {
            return `${this.commonService.translateService.instant('From')} ${formatDate(notification.validFromDate, 'dd. MM. YYYY mm:hh', 'en-US')} ${this.commonService.translateService.instant('Until').toLowerCase()} ${formatDate(notification.validUntilDate, 'dd. MM. YYYY mm:hh', 'en-US')}`;
        }
        return '';
    }
}
