import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'app-active-filters',
    templateUrl: './active-filters.component.html',
    styleUrls: ['./active-filters.component.scss'],
    providers: [CommonService]
})
export class ActiveFiltersComponent implements OnChanges {
    private readonly SCROLL_OFFSET = 100;

    @Input() listOfActiveFilters: { label: string; value: string }[];
    @Output() clearFilter = new EventEmitter<string>();
    @ViewChild('scrollContainer') scrollContainer: ElementRef;
    showLeftScroll = false;
    showRightScroll = false;

    ngOnChanges() {
        if (this.scrollContainer) {
            this.checkScrollPosition(this.scrollContainer.nativeElement);
        }
    }

    addSpacesBetweenWords(filterLabel: string): string {
        return filterLabel
            .trim()
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    }

    scrollToNextFilter(scrollContainer: HTMLElement) {
        scrollContainer.scrollLeft += this.SCROLL_OFFSET;
    }

    scrollToPreviousFilter(scrollContainer: HTMLElement) {
        scrollContainer.scrollLeft -= this.SCROLL_OFFSET;
    }

    checkScrollPosition(scrollContainer: HTMLElement) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
        this.showLeftScroll = scrollLeft > 0;
        this.showRightScroll = scrollLeft < scrollWidth - clientWidth && scrollWidth > clientWidth;
    }
}
