<div class="action-bar-container flex min-h-10 flex-col">
    <div class="flex flex-wrap gap-2">
        @for (group of groups; track $index) {
            @if ($index !== 0) {
                <div class="faded-separator w-0.5 self-stretch"></div>
            }
            <div class="flex flex-col items-center">
                <div class="flex">
                    @if (!collapsed) {
                        @for (item of group.items; track $index) {
                            <button
                                kendoButton
                                fillMode="flat"
                                title="{{ item.label | translate }}"
                                [disabled]="item.isDisabled"
                                (click)="item.onClick()">
                                <div class="flex flex-col items-center text-xs">
                                    <ng-icon [name]="item.icon" />
                                    @if (buttonLabelsSettings.display === 'regular') {
                                        <span>{{ item.label | translate }}</span>
                                    } @else if (buttonLabelsSettings.display === 'animate') {
                                        <app-animated-span
                                            [text]="item.label | translate"
                                            [maxWidthPx]="buttonLabelsSettings.maxWidth"
                                            [scrollingSpeed]="buttonLabelsSettings.scrollingSpeed"></app-animated-span>
                                    } @else if (buttonLabelsSettings.display === 'abbreviate') {
                                        {{ item.label | translate | abbreviate: buttonLabelsSettings.maxChars }}
                                    }
                                </div>
                            </button>
                        }
                    }
                </div>
                @if (collapsed) {
                    <app-abdropdown-menu [label]="group.label" [items]="group.items"></app-abdropdown-menu>
                }
            </div>
        }
    </div>
    @if (groups.length) {
        <div class="relative self-center">
            <button
                class="collapse-icon absolute top-[-6px] px-2 opacity-0 transition-opacity"
                (click)="toggleCollapsed()">
                <ng-icon class="rotate-icon" [name]="'faSolidAngleUp'" [class.rotate]="collapsed" />
            </button>
        </div>
    }
</div>
