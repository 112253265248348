import { Component, OnDestroy } from '@angular/core';
import { CommonService } from '@common/services/common.service';
import { environment } from '@environments/environment.base';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Notification } from '../models/notification.interface';

@Component({ template: '' })
export abstract class BaseNotificationListComponent implements OnDestroy {
    abstract queryName: string;

    data: Notification[] = [];
    loading: boolean = true;
    loadSize = 15;
    total: number;

    filterOpen = false;
    filter: any;
    persistFilter = environment.settings.list.persistFilter;

    notifications$: Subscription = new Subscription();

    constructor(protected commonService: CommonService) {
        this.filter = this.getFilter();
    }

    ngOnDestroy() {
        this.notifications$.unsubscribe();
    }

    search(sortByUnreadFirst = false) {
        this.filter = { ...this.filter, skip: this.data.length, take: this.loadSize };
        this.saveFilter();
        this.getData(sortByUnreadFirst);
    }

    clearFilter(name?: string) {
        if (name) {
            this.filter[name] = null;
        } else {
            this.filter = this.getDefaultFilter();
        }
        this.search();
    }

    toggleFilter() {
        this.filterOpen = !this.filterOpen;
    }

    getDefaultFilter() {
        return { tab: 'All' };
    }

    resetData() {
        this.loadSize = 10;
        this.data = [];
    }

    protected getPersistedFilter() {
        if (this.persistFilter === false) return {};

        try {
            const filter = this.commonService.rememberStateService.get<string>(`filter:${this.commonService.router.url}`);
            return JSON.parse(filter);
        } catch (error) {
            return {};
        }
    }

    protected saveFilter() {
        this.commonService.rememberStateService.set(`filter:${this.commonService.router.url}`, JSON.stringify(this.filter));
    }

    protected getFilter() {
        const persistedFilter = this.getPersistedFilter();
        const filter = this.getDefaultFilter();
        return this.persistFilter && _.isObject(filter) ? { ...filter, ...persistedFilter } : filter;
    }

    private async getData(sortByUnreadFirst = false) {
        this.loading = true;

        if (this.notifications$) {
            this.notifications$.unsubscribe();
        }

        this.notifications$ = this.commonService.queryService
            .getQuery(this.queryName, this.filter, { injector: this.commonService.injector })
            .result$.subscribe((data: any) => {
                if (!data.data) return;
                this.total = data.data.inlineCount;
                if (this.data.length >= this.total) {
                    this.loading = false;
                    return;
                }
                this.data = this.data.concat(data.data.results);
                if (sortByUnreadFirst) {
                    this.data = _.orderBy(this.data, ['seen', 'createdDate'], ['asc', 'desc']);
                }
                this.loading = false;
            });
    }
}
