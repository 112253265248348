import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
@Injectable({
    providedIn: 'root'
})
export class RememberStateService {
    private rememberState = environment.settings.rememberState;

    get<type>(storageKey: string, defaultValue?: type): type {
        const savedState = localStorage.getItem(storageKey.replace(/\/edit|\/view/, ''));
        if (!this.shouldRememberStateForKey(storageKey) || !savedState || savedState === 'undefined')
            return defaultValue;
        try {
            return JSON.parse(savedState) as type;
        } catch (e) {
            console.error('Error retrieving saved state, using default value');
            return defaultValue;
        }
    }

    set<type>(storageKey: string, value: type): void {
        if (!this.shouldRememberStateForKey(storageKey)) return;
        storageKey = storageKey.replace(/\/edit|\/view/, '');
        localStorage.setItem(storageKey, JSON.stringify(value));
    }

    remove(storageKey: string): void {
        localStorage.removeItem(storageKey);
    }

    clear(exclude?: string[]): void {
        if (!exclude) return localStorage.clear();
        Object.keys(localStorage).forEach((key) => {
            if (exclude.some((e) => key === e)) return;
            localStorage.removeItem(key);
        });
    }

    shouldRememberStateForKey(storageKey: string): boolean {
        const keyMatches = Object.keys(this.rememberState).find((key: string) => storageKey.includes(key));
        return this.rememberState[keyMatches] ?? true;
    }
}
