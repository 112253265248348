import { Component, Input } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { Indicator } from '@common/interfaces/indicator.interface';

@Component({
    selector: 'app-meta-data',
    templateUrl: './app-meta-data.component.html'
})
export class AppMetaDataComponent {
    @Input() active: boolean;
    @Input() icon: IconKey;
    @Input() indicators: Indicator[];
    @Input() isSidebarPinned: boolean;
}
