import { PropertyFunction } from './util.interface';

export function resolveProperty<T>(property: T | PropertyFunction<T>): T {
    return typeof property === 'function' ? (property as PropertyFunction<T>)() : property;
}

/**
 * Utility function to assign a type to an object.
 * It's usefull in templates when Angular can't infer the type of an object.
 * @param obj object to convert to type T
 * @returns obj typed as T
 */
export function toType<T>(obj: any): T {
    return obj as T;
}
