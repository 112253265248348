import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';
import { BaseInputComponent } from '../base-input/base-input.component';
import { BooleanInputConfig } from '../input.type';

/**
 * Boolean input component.
 * @param {boolean} nullable - Whether the input can be null and allows for a third state.
 */
@Component({
    selector: 'app-boolean-input',
    templateUrl: './boolean-input.component.html',
    providers: [{ provide: BaseInputComponent, useExisting: BooleanInputComponent }]
})
export class BooleanInputComponent extends BaseInputComponent<boolean> {
    tape = [true, null, false];
    @Input() nullable: BooleanInputConfig['nullable'] = false;

    override onValueChange(_value: boolean): void {
        const nextValue = this.nullable
            ? this.tape[(this.tape.indexOf(this.value()) + 1) % this.tape.length]
            : !this.value();

        super.onValueChange(nextValue);
    }

    override validate(): void {
        const error: string[] = [];

        if (this.isEditMode && this.isRequired && (this.nullable ? this.value() === null : false)) {
            error.push('This field is required.');
        }

        this.error.set(error.length > 0 ? error : null);
    }

    /**
     * A bit off a mess, but taken from a TW elements checkbox component, to override vanila checkbox styles
     * and better match with our design system.
     */
    override generateInputStyle = () =>
        twMerge(
            `
            border-muted bg-input before:shadow-checkbox checked:focus:before:shadow-checkbox
            relative float-left mt-[0.15rem] h-[1.125rem] w-[1.125rem] cursor-none appearance-none rounded-md
            border-[0.125rem] border-solid outline-none before:pointer-events-none before:absolute
            before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent
            before:opacity-0 before:shadow-transparent before:content-[''] checked:border-primary checked:bg-primary
            checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ms-[0.25rem]
            checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45
            checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid
            checked:after:border-white checked:after:bg-transparent checked:after:content-[''] indeterminate:border-primary
            indeterminate:bg-primary indeterminate:after:absolute indeterminate:after:ms-[0.2rem] indeterminate:after:mt-[6px]
            indeterminate:after:w-[0.5rem] indeterminate:after:border-[0.05rem] indeterminate:after:border-solid
            indeterminate:after:border-white hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-black/60
            focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12]
            focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute
            focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem]
            focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]
            checked:focus:after:-mt-px checked:focus:after:ms-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem]
            checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem]
            checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white
            checked:focus:after:bg-transparent indeterminate:focus:after:w-[0.5rem] indeterminate:focus:after:rounded-none
            indeterminate:focus:after:border-[0.125rem] indeterminate:focus:after:border-b-0 indeterminate:focus:after:border-e-0
            indeterminate:focus:after:border-s-0 dark:border-muted dark:checked:border-primary dark:checked:bg-primary
            dark:indeterminate:border-primary dark:indeterminate:bg-primary dark:after:bg-input rtl:float-right
            disabled:opacity-50 disabled:cursor-not-allowed disabled:before:shadow-none disabled:before:opacity-0`,
            this.error()?.length > 0 && 'indeterminate:border-danger/80 indeterminate:before:border-danger/80'
        );
}
