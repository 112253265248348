import { ViewMode } from '@common/models/view-mode';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';
import gitVersionInfo from '../../git-version-info.json';
import packageJson from '../../package.json';

const serverUrl = `${window.origin}/be`;
const apiUrl = `${serverUrl}/api`;

export const environment = {
    name: 'base',
    serverUrl,
    apiUrl,
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    title: 'project-template',
    version: packageJson.version,
    gitVersionInfo,
    defaultLanguage: 'en',

    settings: {
        header: {
            logo: '/assets/img/logo.svg',
            welcomeMessage: 'Welcome to',
            themeColor: 'dark',
            environmentColor: '#fff'
        },
        appControl: {
            multi: false,
            time: true,
            codelist: {
                take: 100
            },
            fileDownloadUrl: `/query/DownloadAttachment`,
            fileRestrictions: {
                allowedExtensions: [
                    '.jpg',
                    '.jpeg',
                    '.png',
                    '.bmp',
                    '.tif',
                    '.doc',
                    '.docx',
                    '.xls',
                    '.xlsx',
                    '.txt',
                    '.pdf',
                    '.xslt'
                ],
                allowedImageExtensions: ['.jpg', '.jpeg', '.png', '.bmp', '.tif']
            },
            dropdown: {
                popupSettings: undefined as PopupSettings
            },
            dateFormat: 'dd/MM/yy',
            timeFormat: 'H:mm:ss'
        },
        grid: {
            pageable: { buttonCount: 4, info: true, type: 'numeric', pageSizes: [10, 20, 50] },
            pageSize: 20,
            sortable: false,
            sort: [
                {
                    field: 'id',
                    dir: 'asc'
                }
            ],
            filterable: true,
            reorderable: true,
            resizable: true,
            compressedGridValue: '4px',
            autoFitColumns: true
        },
        view: {
            defaultViewMode: ViewMode.view,
            save: {
                redirectToViewMode: ViewMode.view
            }
        },
        list: {
            persistFilter: true,
            rememberFilterState: true
        },
        roundingSettings: {
            price: 3,
            quantity: 3,
            amount: 2,
            taxAmount: 2,
            discountPercentage: 2
        },
        rememberState: {
            actionBar: true,
            darkMode: true,
            filters: true,
            filter: true,
            language: true,
            sidebarPinned: true,
            toggleableFilter: true,
            gridSettings: true
        }
    }
};
