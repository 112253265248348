import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'abbreviate',
    standalone: true
})
export class AbbreviatePipe implements PipeTransform {
    transform(value: string, maxLength: number = 10): string {
        if (typeof value !== 'string') {
            throw new Error('Invalid input: AbbreviatePipe expects a string');
        }

        if (value.length <= maxLength) {
            return value;
        }

        return value
            .split(' ')
            .map((word) => word[0])
            .join('')
            .toUpperCase()
            .slice(0, maxLength);
    }
}
