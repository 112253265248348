import { fakedCodelists } from '@administration/codelists/codelists.interface';
import { Component, Input } from '@angular/core';
import { ColumnSettings, EditableColumnSettings } from '@common/interfaces/column-settings.interface';
import { ValueOf } from '@common/models/util.interface';
import { ViewMode } from '@common/models/view-mode';

@Component({
    selector: 'app-grid-cell-template',
    templateUrl: './grid-cell-template.component.html'
})
export class GridCellTemplateComponent {
    @Input() defaultViewMode: ValueOf<typeof ViewMode>;
    @Input() parentRoute: string;
    @Input() row: any;
    @Input() column: ColumnSettings | EditableColumnSettings;
    fakedCodelistArray: string[] = Object.entries(fakedCodelists).map(([, value]) => value);
}
