import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'app-link',
    template: `
        @if (showLink) {
            <a class="cursor-pointer !text-link" [routerLink]="route" [queryParams]="queryParams">
                <ng-container *ngTemplateOutlet="linkContent"></ng-container>
            </a>
        } @else {
            <ng-container *ngTemplateOutlet="linkContent"></ng-container>
        }
        <ng-template #linkContent>
            <ng-content></ng-content>
        </ng-template>
    `
})
export class AppLinkComponent implements OnInit {
    hasPermission: boolean = true;
    showLink: boolean;

    @Input() route: string | string[] | any[];
    @Input() queryParams: Record<string, number | string>;
    @Input() checkPermission: boolean = true;
    @Input() permission: string = '';

    constructor(protected common: CommonService) {}

    ngOnInit(): void {
        if (this.checkPermission) {
            const user = this.common.userService.currentUserSubject.getValue();

            if (!this.permission) {
                this.permission = mapRouteToPermission(this.route);
            }

            this.hasPermission =
                user.hasPermission(`${this.permission}.View`) ||
                user.hasPermission(`${this.permission}.ViewOwn`) ||
                user.hasPermission(`${this.permission}.ViewAll`);
        }

        // show link if user has permission and last route element is not null
        this.showLink = this.hasPermission && this.route.at(-1) !== null;

        function mapRouteToPermission(route: string | string[] | any[]): string {
            let entity: string = typeof route === 'string' ? route.split('/')[1] : '';
            entity = route[0].replace(/^\//, '');
            entity = entity.charAt(0).toUpperCase() + entity.slice(1);
            return entity.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        }
    }
}
