<div class="ml-10 flex flex-wrap items-center justify-between">
    <kendo-badge-container>
        <kendo-badge
            class="-mt-3 rounded-xl py-1"
            size="large"
            position="inside"
            [align]="{ horizontal: 'end' }"
            [themeColor]="active ? 'success' : 'warning'">
            {{ (active ? 'Active' : 'Inactive') | translate }}
        </kendo-badge>
    </kendo-badge-container>
    @if (indicators) {
        <div class="ml-16 flex flex-wrap gap-5">
            @for (indicator of indicators; track $index) {
                <div kendoTooltip title="{{ indicator.title | translate }}">
                    <ng-icon [color]="indicator.color" [name]="indicator.icon" />
                </div>
            }
        </div>
    }
</div>
