import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { EditableColumnSettings } from '@common/interfaces/column-settings.interface';
import { EditableGridSettings } from '@common/interfaces/grid-settings.interface';
import { CommonService } from '@common/services/common.service';
import { GridEditService } from '@common/services/grid-edit.service';
import { environment } from '@environments/environment';
import {
    AddEvent,
    CancelEvent,
    ColumnBase,
    CommandColumnComponent,
    DataStateChangeEvent,
    EditEvent,
    GridDataResult,
    RemoveEvent,
    SaveEvent
} from '@progress/kendo-angular-grid';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { AppControlComponent, AppControlType } from '../app-control/app-control.component';
import { AppGridComponent } from '../app-grid/app-grid.component';
import { RowAction } from './editable-grid.interface';

@Component({
    selector: 'app-editable-grid',
    templateUrl: './editable-grid.component.html',
    styleUrl: './editable-grid.component.scss',
    providers: [GridEditService],
    encapsulation: ViewEncapsulation.None
})
export class EditableGridComponent extends AppGridComponent implements OnInit, OnDestroy, OnChanges {
    override _cols: EditableColumnSettings[] = [];
    override gridSettings: EditableGridSettings;

    localState: State = { skip: 0, take: this.pageSize };
    localGridData: GridDataResult = { data: [], total: 0 };

    @Input() title: string;
    @Input() entityName: string;
    @Input() editMode: boolean;
    @Input() canAddNew = true;
    @Input() canDelete = true;
    @Input() canEdit = true;
    @Input() canEditRow: (row: any) => boolean = () => true;
    @Input() canExport = false;
    @Input() initialData = {};
    @Input() override sort = [...environment.settings.grid.sort] as SortDescriptor[];
    @Input() validateRow: (row: any) => boolean = () => true;
    @Input() unique: EditableColumnSettings[] = [];
    @Input() canClone = false;
    @Input() removeOnDelete = true;
    @Input() override autoFitColumns = false;
    @Input() override allowGridSave = false;
    @Input() override set cols(value: EditableColumnSettings[]) {
        this._cols = value.map((col, index) => ({
            type: 'string' as AppControlType,
            isVisible: true,
            width: col.width === undefined ? (index === value.length - 1 ? null : 200) : col.width,
            editTemplate: true,
            ...col
        }));
    }
    override get cols(): EditableColumnSettings[] {
        return this._cols;
    }

    @Output() rowAction = new EventEmitter<RowAction>();
    @Output() saveEvent = new EventEmitter<SaveEvent>();
    @Output() addEvent = new EventEmitter<AddEvent>();
    @Output() removeEvent = new EventEmitter<RemoveEvent>();
    @Output() exportEvent = new EventEmitter<any>();
    @ViewChildren(AppControlComponent) appControls: AppControlComponent[];
    @ViewChildren(CommandColumnComponent) protected commandColumns: Array<CommandColumnComponent>;

    isEditing = false;

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        commonService: CommonService,
        router: Router,
        public gridEdit: GridEditService,
        ngZone: NgZone
    ) {
        super(changeDetectorRef, commonService, router, ngZone);
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.localState = {
            skip: this.state.skip,
            take: this.state.take
        };
    }

    override ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        this.applyLocalPaging(this.gridData, this.localState);
    }

    override onDataStateChanged(state: DataStateChangeEvent): void {
        super.onDataStateChanged(state);
        this.applyLocalPaging(this.gridData, state);
    }

    override updateColumns(columns: ColumnBase[] = this.columns?.toArray() || []) {
        if (this.editMode && this.cols.length == 0) columns.push(...this.commandColumns);
        super.updateColumns(columns);
    }

    override mapGridSettings(gridSettings: EditableGridSettings): EditableGridSettings {
        this.state = gridSettings.state;
        this.queryData();
        return {
            state: this.state,
            columnsConfig: gridSettings.columnsConfig.sort((a: any, b: any) => a.orderIndex - b.orderIndex)
        };
    }

    exportToExcel(): void {
        this.grid.saveAsExcel();
    }

    // Opens a new row for adding
    onAdd(event: AddEvent, form) {
        this.rowAction.emit({ action: 'add' });
        const newDataItem = this.gridEdit.addHandler(event, form, { ...this.initialData, isNew: true });
        this.isEditing = true;

        event.dataItem = newDataItem;
        this.addEvent.emit(event);

        this.addItemToGridData(newDataItem);
    }

    // Edits the selected row
    onEdit(event: EditEvent) {
        this.rowAction.emit({ action: 'edit' });
        this.gridEdit.editHandler(event);
        this.isEditing = true;
    }

    // Cancels the edit action
    onCancel(event: CancelEvent) {
        this.rowAction.emit({ action: 'cancel' });
        this.gridEdit.cancelHandler(event);
        this.isEditing = false;
        const isNew = event.dataItem.isNew;

        if (isNew) {
            this.data.splice(event.rowIndex, 1);
            this.gridData.total--;

            this.applyLocalPaging(this.data, {
                skip: this.localState.skip - 1,
                take: this.localState.take
            });
        }
    }

    onSave(event: SaveEvent) {
        // Check if valid
        if (!this.validateRow(event.dataItem)) {
            this.commonService.toastrNotificationService.show({
                type: 'error',
                message: 'Some required fields are empty or invalid'
            });
            return;
        }

        // TODO - this only checks data in the grid, not the data in the database.
        // Check if unique
        const isUnique = this.unique
            .filter((x) => x.required)
            .every((prop) => {
                const index = this.gridData.data
                    // Filter out all rows that don't have an id or in case that event.dataItem has an id, filter out the row with the same id
                    .filter((row) => row != event.dataItem)
                    .filter((row) => (row.id ? row.id !== event.dataItem.id : true))
                    .findIndex((item) => item[prop.field] === event.dataItem[prop.field]);
                return index === -1;
            });

        if (!isUnique) {
            this.commonService.toastrNotificationService.show({
                type: 'error',
                message: 'The following properties must be unique',
                addendum: this.unique
                    .filter((x) => x.required)
                    .map((x) => x.title)
                    .toString()
            });
            return;
        }

        // If event.dataItem doesn't have an id, it's a new row
        const isNew = event.dataItem.isNew;
        if (isNew) {
            // Adding new
            this.gridData.total++;
        } else {
            // Editing existing
            const editedRowIndex = this.gridData.data.findIndex((item) => item.id === event.dataItem.id);
            this.gridData.data.find[editedRowIndex] = event.dataItem;
        }
        this.rowAction.emit({ action: 'save' });
        this.saveEvent.emit({ ...event });

        // Validate if the add is valid
        this.gridEdit.saveHandler(event);
        this.isEditing = false;
    }

    onRemove(event: RemoveEvent) {
        this.rowAction.emit({ action: 'remove' });
        this.removeEvent.emit(event);
        if (!this.removeOnDelete) return;

        this.data.splice(event.rowIndex, 1);
        this.gridData.total--;

        this.applyLocalPaging(this.data, {
            skip: this.localState.skip - 1,
            take: this.localState.take
        });
    }

    onExcelExport(args: any): void {
        args.preventDefault();
        this.exportEvent.emit(args);
    }

    onClone(item, form) {
        this.rowAction.emit({ action: 'clone' });
        if (!this.validateRow(item)) {
            this.commonService.toastrNotificationService.show({
                type: 'error',
                message: 'Some required fields are empty'
            });
            return;
        }
        const clonedDataItem = this.gridEdit.cloneHandler(this.grid, form, item);
        this.isEditing = true;

        this.addItemToGridData(clonedDataItem);
    }

    private applyLocalPaging(gridData: GridDataResult | any[], state: State): void {
        const skip = state.skip > 0 ? state.skip : 0;

        const dataArray = Array.isArray(gridData) ? gridData : gridData.data;

        this.localState = state;
        this.localGridData = {
            data: dataArray.slice(skip, skip + state.take),
            total: dataArray.length
        } as GridDataResult;
    }

    private addItemToGridData(item: object): void {
        this.gridData.total = this.data.unshift(item);

        this.applyLocalPaging(this.data, {
            skip: 0,
            take: this.localState.take
        });
    }
}
