import { Component, Input } from '@angular/core';
import { EditableColumnSettings } from '@common/interfaces/column-settings.interface';

@Component({
    selector: 'app-grid-edit-template',
    templateUrl: './grid-edit-template.component.html'
})
export class GridEditTemplateComponent {
    @Input() row: any;
    @Input() column: EditableColumnSettings;
}
