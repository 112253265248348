import { Injectable } from '@angular/core';
import { UserService } from '@common/services/user.service';
import { environment } from '@environments/environment';
import * as signalR from '@microsoft/signalr';
import { HubConnectionState } from '@microsoft/signalr';
import _ from 'lodash';
import { catchError, from, Subject } from 'rxjs';

export type SignalrMessage = {
    message: string;
    topic: string;
    type: string;
};

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {
    private hubConnection: signalR.HubConnection;
    private data: any[] = [];
    notifications$ = new Subject<SignalrMessage>();

    constructor(private userService: UserService) {
        this.setupHub();

        userService.currentUserSubject.pipe(catchError((err) => from(console.error(err) as any))).subscribe((user) => {
            if (user) {
                if (
                    this.hubConnection.state !== HubConnectionState.Connected &&
                    this.hubConnection.state !== HubConnectionState.Connecting
                ) {
                    return this.hubConnection
                        .start()
                        .then(() => this.flush())
                        .catch((err) => console.error(`Error while establishing SignalR connection: ${err}`));
                }
            } else if (
                this.hubConnection.state !== HubConnectionState.Disconnected &&
                this.hubConnection.state !== HubConnectionState.Disconnecting
            ) {
                return this.hubConnection.stop();
            }

            return false;
        });
    }

    private setupHub() {
        try {
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`${environment.serverUrl}/hubs/ws`, {
                    accessTokenFactory: () => this.userService.getAccessToken()
                    // transport: signalR.HttpTransportType.ServerSentEvents
                })
                .configureLogging(environment.name === "production" ? signalR.LogLevel.Warning : signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();

            this.hubConnection.on('TotalClients', (totalClients) => {
                if (!(environment.name === "production")) {
                    console.info('TotalClients', totalClients);
                }
            });

            this.hubConnection.on('Notify', (data: SignalrMessage) => {
                this.notifications$.next(data);
            });

            return true;
        } catch (error) {
            return false;
        }
    }

    private flush() {
        _.forEach(this.data, (x) => {
            this.hubConnection.invoke(x.level, x.message).then(x.resolve).catch(x.reject);
        });

        this.data = [];
    }

    log(message: string, level: string) {
        if (this.hubConnection.state === HubConnectionState.Connected) {
            return this.hubConnection.invoke(level, message).catch((error) => console.error(error));
        }

        return new Promise((resolve, reject) => {
            this.data.push({
                level,
                message,
                resolve,
                reject
            });
        });
    }

    success(message: string) {
        return this.log(message, 'success');
    }

    info(message: string) {
        return this.log(message, 'info');
    }

    warn(message: string) {
        return this.log(message, 'warn');
    }

    error(message: string) {
        return this.log(message, 'error');
    }
}
