import {
    CodeListAddTranslationParameters,
    CodeListRemoveTranslationParameters,
    CodeListTranslation,
    CodeListUpdateTranslationParameters,
    TranslationsGridDataItem
} from '@administration/codelists/codelists.interface';
import { codelists } from '@administration/codelists/codelists.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RowAction } from '@common/components/editable-grid/editable-grid.interface';
import { toType } from '@common/models/util';
import { CommonService } from '@common/services/common.service';
import { DialogService } from '@common/services/dialog.service';
import { tapSuccessResult } from '@ngneat/query';
import { TranslateService } from '@ngx-translate/core';
import { RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-codelist-translations',
    templateUrl: './codelist-translations.component.html'
})
export class CodeListTranslationsComponent implements OnInit, OnDestroy {
    private _translations: CodeListTranslation[];
    private destroy$ = new Subject<void>();

    @Input({ required: true }) editMode = true;
    @Input({ required: true })
    set translations(input: CodeListTranslation[]) {
        this._translations = input.map((t) => ({ ...t, languageId: t.language.id }));
    }
    get translations() {
        return this._translations;
    }
    @Input({ required: true }) codelistItemId: string;
    @Input({ required: true }) codelistName: string;

    uniqueProperties = ['languageId'];
    notSetLanguagesCount = 0;

    toTranslation = (t: object) => toType<CodeListTranslation>(t);

    @Output() createTranslation = new EventEmitter<CodeListAddTranslationParameters>();
    @Output() updateTranslation = new EventEmitter<CodeListUpdateTranslationParameters>();
    @Output() removeTranslation = new EventEmitter<CodeListRemoveTranslationParameters>();

    constructor(
        private dialog: DialogService,
        private common: CommonService,
        private t: TranslateService
    ) {}

    ngOnInit(): void {
        this.fetchRemainingLanguages();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    removeHandler({ dataItem }: RemoveEvent) {
        this.dialog
            .confirm({
                options: {
                    title: this.t.instant('Remove code list item'),
                    message: this.t.instant('Are you sure you want to remove the code list item?')
                }
            })
            .then((result) => {
                if (result === true) {
                    this.delete(dataItem);
                    this.notSetLanguagesCount++;
                }
            });
    }

    saveHandler({ dataItem }: SaveEvent) {
        if (dataItem.isNew) {
            this.create(dataItem);
        } else {
            this.update(dataItem);
        }
    }

    existingTranslationsLanguageIds() {
        return this._translations.map((t) => t.languageId).filter((t) => t != null);
    }

    handleRowAction({ action }: RowAction) {
        switch (action) {
            case 'add':
                this.notSetLanguagesCount--;
                break;
            case 'cancel':
                this.notSetLanguagesCount++;
                break;
        }
    }

    private fetchRemainingLanguages() {
        this.common.queryService
            .getCodelistQuery({
                name: codelists.language,
                omittedIds: this.existingTranslationsLanguageIds(),
                injector: this.common.injector
            })
            .result$.pipe(
                tapSuccessResult<any>((data: []) => {
                    this.notSetLanguagesCount = data.length;
                    this.destroy$.next();
                    this.destroy$.complete();
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    private create(dataItem: TranslationsGridDataItem) {
        const data: CodeListAddTranslationParameters = {
            LanguageId: dataItem.languageId,
            Name: dataItem.name,
            LocalizedCode: dataItem.localizedCode,
            CodelistId: this.codelistItemId,
            CodelistName: this.codelistName
        };

        this.createTranslation.emit(data);
    }

    private update(dataItem: TranslationsGridDataItem) {
        const languageItemId = this.translations.find((t) => t.languageId === dataItem.languageId).id;
        const data: CodeListUpdateTranslationParameters = {
            CodeListLanguageId: languageItemId,
            LanguageId: dataItem.languageId,
            CodeListName: this.codelistName,
            LocalizedCode: dataItem.localizedCode,
            Name: dataItem.name
        };

        this.updateTranslation.emit(data);
    }

    private delete(dataItem: TranslationsGridDataItem) {
        const translationItem = this.translations.find((t) => t.languageId === dataItem.languageId);
        const data: CodeListRemoveTranslationParameters = {
            CodelistName: this.codelistName,
            Id: translationItem.id,
            CodelistId: this.codelistItemId,
            LanguageId: dataItem.languageId
        };

        this.translations.splice(this.translations.indexOf(translationItem), 1);
        this.removeTranslation.emit(data);
    }
}
