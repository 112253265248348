import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-input-error',
    template: `
        <div class="text-xs text-danger">{{ error | translate }}</div>
    `
})
export class InputErrorComponent {
    @Input() error: string;
}
