import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Setting } from '@common/interfaces/setting.interface';
import { SettingKeyCodes } from '@common/known-types/setting-key.codes';
import { CommonService } from '@common/services/common.service';
import { environment } from '@environments/environment.base';

@Pipe({
    name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
    defaultDateFormat: string = environment.settings.appControl.dateFormat;

    constructor(
        private datePipe: DatePipe,
        private commonService: CommonService
    ) {
        this.getDefaultDateFormat();
    }

    private getDefaultDateFormat() {
        const settings = this.commonService.queryService.queryClient.getQueryData<Setting[]>(['settings']);
        if (!settings) return;
        const defaultDateFormat = settings.find(
            (setting: Setting) => setting.key === SettingKeyCodes.DateFormat
        )?.value;
        if (defaultDateFormat) this.defaultDateFormat = defaultDateFormat;
    }

    transform(value: Date | string): string | null {
        return this.datePipe.transform(value, this.defaultDateFormat);
    }
}
