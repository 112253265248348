<app-input-label [label]="label" [labelPosition]="labelPosition" [for]="id" [isRequired]="isRequired">
    @if (isEditMode) {
        <input
            type="checkbox"
            [id]="id"
            [title]="title | translate"
            [disabled]="isDisabled"
            [required]="isRequired"
            [indeterminate]="nullable ? value() === null : false"
            [checked]="value()"
            [ngClass]="generateInputStyle()"
            (click)="onValueChange(true)"
            (inputFocus)="onFocusIn()"
            (inputBlur)="onFocusOut()" />
        <app-input-error [error]="error()?.[0]" />
    } @else {
        @if (value() === true) {
            <ng-icon class="text-success" name="faSolidCheck" />
        } @else if (value() === false) {
            <ng-icon class="text-danger" name="faSolidXmark" />
        } @else {
            -
        }
    }
</app-input-label>
