<div
    [ngClass]="{
        flex: true,
        'flex-col items-start justify-center': labelPosition === undefined || labelPosition === 'top',
        'gap-2': labelPosition === 'right' || labelPosition === 'left'
    }">
    @if (label !== undefined && label !== '') {
        <label
            for="{{ for }}"
            [ngClass]="{
                'ng-star-inserted text-no-wrap flex w-full select-none justify-between text-xs font-normal text-neutral-400': true,
                'order-2': labelPosition === 'right'
            }">
            <div>
                {{ label | translate }}
                {{ isRequired ? '*' : '' }}
            </div>
            @if (characterCount && focused) {
                <span class="relative">{{ characterCount }}</span>
            }
        </label>
    }
    <ng-content></ng-content>
</div>
