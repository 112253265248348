import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

export type ToastType = 'success' | 'error' | 'info' | 'warning';

export interface Toast {
    type: ToastType;
    message?: string;
    addendum?: string;
    title?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ToastrNotificationService {
    constructor(
        public toastrService: ToastrService,
        private translateService: TranslateService
    ) {}

    private formatMessage(message?: string, addendum?: string) {
        return `${message ? this.translateService.instant(message) : ''}${addendum ? `: ${addendum}` : ''}`;
    }

    public show<ConfigPayload = any>(
        toast: Toast,
        override?: Partial<IndividualConfig<ConfigPayload>>
    ): ActiveToast<any> {
        return this.toastrService[toast.type](
            this.formatMessage(
                toast.message ?? toast.type.charAt(0).toUpperCase() + toast.type.slice(1),
                toast.addendum
            ),
            toast.title ? this.translateService.instant(toast.title) : '',
            override
        );
    }
}
