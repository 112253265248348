import { afterNextRender, ChangeDetectorRef, Component, ElementRef, Input, viewChild } from '@angular/core';

@Component({
    selector: 'meta-data-item',
    templateUrl: './meta-data-item.component.html'
})
export class MetaDataItemComponent {
    @Input() label: string;
    @Input() value: string;
    overflowing: boolean = false;

    constructor(
        private element: ElementRef,
        changeDetectorRef: ChangeDetectorRef
    ) {
        afterNextRender(() => {
            const wrapElement: HTMLElement = this.element.nativeElement.children[0];
            const valueElement: HTMLElement = wrapElement.children[1] as HTMLElement;

            if (valueElement.scrollWidth > valueElement.clientWidth) {
                this.overflowing = true;
                changeDetectorRef.detectChanges();
            }
        });
    }
    spanElement = viewChild<ElementRef<HTMLSpanElement>>('metaDataValue');

    getTooltip(): string {
        return this.overflowing ? this.value : '';
    }
}
