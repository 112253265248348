import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-radio-button-selection',
    templateUrl: './radio-button-selection.component.html'
})
export class RadioButtonSelectionComponent {
    @Input() options: string[] = [];
    @Input() labelPosition: 'top' | 'bottom' | 'left' | 'right' = 'right'; // Default position is right
    @Input() selectedOption: string;
    @Output() selectionChange = new EventEmitter<string>();

    constructor() {}
}
