import { AfterViewInit, ChangeDetectorRef, ContentChildren, Directive, Input, QueryList } from '@angular/core';
import { AppControlComponent } from '@common/components/app-control/app-control.component';
import { BaseInputComponent } from '@common/components/input/base-input/base-input.component';

@Directive({
    selector: '[setEditMode]'
})
export class SetEditModeDirective implements AfterViewInit {
    @Input() isEditMode: boolean = true;
    @ContentChildren(AppControlComponent, { descendants: true }) appControls!: QueryList<AppControlComponent>;
    @ContentChildren(BaseInputComponent, { descendants: true }) inputs!: QueryList<BaseInputComponent<any>>;

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.applyEditMode();
    }

    applyEditMode(): void {
        if (this.appControls && this.appControls.length > 0)
            this.appControls.forEach((control) => (control.isEditMode = this.isEditMode));
        if (this.inputs && this.inputs.length > 0)
            this.inputs.forEach((control) => (control.isEditMode = this.isEditMode));
        this.cdr.detectChanges();
    }
}
