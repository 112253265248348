import { inject, Injectable, Injector } from '@angular/core';
import { PaginatedQuery } from '@common/interfaces/query.interface';
import { QueryService } from '@common/services/query.service';
import { mapResultData } from '@ngneat/query';
import { QueryObserverResult } from '@tanstack/query-core';
import { Observable } from 'rxjs';
import {
    CodeList,
    CodeListAddTranslationParameters,
    CodeListCreateParameters,
    CodeListDeleteParameters,
    CodeListFilter,
    CodeListItemFull,
    CodeListItemFullFilter,
    CodeListItemFullQuery,
    CodeListProperty,
    CodeListRemoveTranslationParameters,
    CodeListUpdateParameters,
    CodeListUpdateTranslationParameters
} from './codelists.interface';

const endpoints = {
    getCodelist: 'CodeListItems',
    getCodelistItemFull: 'CodeListItemFull',
    addLanguage: 'AddCodeListTranslation',
    removeLanguage: 'DeleteCodeListTranslation',
    updateLanguage: 'UpdateCodeListTranslation',
    update: 'UpdateCodeList',
    delete: 'DeleteCodeList',
    add: 'AddCodeList'
} as const;

const CODELIST = 'codelist';

@Injectable({
    providedIn: 'root'
})
export class CodelistsService {
    injector = inject(Injector);
    constructor(private queryService: QueryService) {}

    defaultProperties = [
        'name',
        'localizedCode',
        'translations',
        'active',
        'version',
        'createdDate',
        'lastModifiedDate',
        'id'
    ] as const satisfies string[];

    getItems(filter: CodeListFilter) {
        return this.queryService.getQuery(endpoints.getCodelist, filter, {
            staleTime: Infinity,
            queryKey: [CODELIST, endpoints.getCodelist, filter.codelistName, filter.take, filter.skip],
            injector: this.injector
        }).result$ as Observable<QueryObserverResult<PaginatedQuery<CodeList>, unknown>>;
    }

    getItemFull(filter: CodeListItemFullFilter) {
        // If no ID is provided, no item is returned, but we still get the properties
        // so we know how to create a new item
        return this.queryService
            .getQuery(endpoints.getCodelistItemFull, filter, {
                staleTime: Infinity,
                queryKey: [CODELIST, endpoints.getCodelistItemFull, filter.codelistName, filter.id],
                injector: this.injector
            })
            .result$.pipe(
                mapResultData((result: CodeListItemFullQuery) => {
                    if (!result.item) return result;
                    return this.replaceCodelistsWithIds(result);
                })
            ) as Observable<QueryObserverResult<CodeListItemFullQuery, unknown>>;
    }

    createTranslation(data: CodeListAddTranslationParameters) {
        return this.queryService.getCommandMutation().mutate({
            command: endpoints.addLanguage,
            data
        });
    }

    removeTranslation(data: CodeListRemoveTranslationParameters) {
        return this.queryService.getCommandMutation().mutate({
            command: endpoints.removeLanguage,
            data
        });
    }

    updateTranslation(data: CodeListUpdateTranslationParameters) {
        return this.queryService.getCommandMutation().mutateAsync({
            command: endpoints.updateLanguage,
            data
        });
    }

    updateItem(data: CodeListUpdateParameters) {
        return this.queryService.getCommandMutation().mutateAsync({
            command: endpoints.update,
            data,
            invalidate: [CODELIST]
        });
    }

    addItem(data: CodeListCreateParameters) {
        return this.queryService.getCommandMutation().mutateAsync({
            command: endpoints.add,
            data,
            invalidate: [CODELIST]
        });
    }

    deleteItem(data: CodeListDeleteParameters) {
        return this.queryService.getCommandMutation().mutateAsync({
            command: endpoints.delete,
            data,
            invalidate: [CODELIST]
        });
    }

    /**
     * Replace the properties which are codelist objects with just their ID
     */
    replaceCodelistsWithIds(itemResult: CodeListItemFullQuery) {
        itemResult.properties.forEach((property) => {
            if (typeof itemResult.item[property.key] !== 'object') {
                // Either it's not a codelist property or we already converted it to just the ID previously
                return;
            }
            if (property.dataType === 'codeList') {
                itemResult.item[property.key] = itemResult.item[property.key]?.id;
            }
        });
        return itemResult;
    }

    /**
     * Filter out the default properties from the item
     * @param item
     * @returns item with only the properties that are not default
     */
    filterDefaultProperties(item: CodeListItemFull) {
        const keys = Object.keys(item).filter((key) => !(this.defaultProperties as string[]).includes(key));
        return keys.reduce((obj, key) => {
            obj[key] = item[key];
            return obj;
        }, {});
    }

    /**
     * Creates a new model with default values for all properties
     */
    newModel(model: CodeListItemFullQuery): CodeListItemFullQuery {
        const item: CodeListItemFull = { id: null };
        model.properties.forEach((property) => {
            item[property.key] = property.defaultValue;
        });
        return { ...model, item };
    }

    getModelCustomProperties(properties: CodeListProperty[]) {
        return properties.filter((p) => !(this.defaultProperties as string[]).includes(p.key));
    }
}

export const codelists = {
    attachmentType: 'AttachmentType',
    country: 'Country',
    language: 'Language',
    organizationRelationType: 'OrganizationRelationType',
    organizationType: 'OrganizationType',
    setting: 'Setting',
    settingCategory: 'SettingCategory',
    vesselVisitStatus: 'VesselVisitStatus'
} as const satisfies Record<string, string>;

export type Codelists = (typeof codelists)[keyof typeof codelists];
