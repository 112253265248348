<app-input-label [label]="label" [labelPosition]="labelPosition" [for]="id" [isRequired]="isRequired">
    @if (isEditMode) {
        <kendo-editor
            [iframe]="iframe"
            [id]="id"
            [ngClass]="class"
            [(value)]="value"
            (valueChange)="onValueChange($event)"
            (inputFocus)="onFocusIn()"
            (inputBlur)="onFocusOut()" />
    } @else {
        <div [id]="id" [innerHTML]="value()"></div>
    }
</app-input-label>
