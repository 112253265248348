import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-animated-span',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './animated-span.component.html'
})
export class AnimatedSpanComponent implements AfterViewInit {
    private _maxWidthPx = 32;
    private _scrollingSpeed = 40;
    @Input() text = '';
    isHovered = false;
    elementWidth = 0;
    animationDuration = 0;

    @Input()
    set maxWidthPx(value: number) {
        this._maxWidthPx = value !== undefined ? value : 32;
    }
    get maxWidthPx(): number {
        return this._maxWidthPx;
    }

    @Input()
    set scrollingSpeed(value: number) {
        this._scrollingSpeed = value !== undefined ? value : 40;
    }
    get scrollingSpeed(): number {
        return this._scrollingSpeed;
    }

    @ViewChild('innerDiv') innerDivRef: ElementRef;

    ngAfterViewInit() {
        setTimeout(() => {
            this.elementWidth = this.innerDivRef.nativeElement.offsetWidth;
            const overflowLength = this.elementWidth - this.maxWidthPx;
            this.animationDuration = (overflowLength / this.scrollingSpeed) * 1000;
        });
    }

    shouldAnimate(): boolean {
        return this.isHovered && this.elementWidth > this.maxWidthPx;
    }
}
