import { Component, Input } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { faSolidSortDown } from '@ng-icons/font-awesome/solid';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ActionBarItemNormalized } from '../action-bar.interface';
import { ABDropdownMenuService } from './abdropdown-menu.service';
import { ClickOutsideDirective } from '@common/directives/click-outside.directive';

@Component({
    selector: 'app-abdropdown-menu',
    standalone: true,
    imports: [ButtonsModule, NgIconComponent, ClickOutsideDirective, TranslateModule],
    templateUrl: './abdropdown-menu.component.html',
    styleUrl: './abdropdown-menu.component.scss',
    viewProviders: [provideIcons({ faSolidSortDown })]
})
export class ABDropdownMenuComponent {
    @Input({ required: true }) label: string;
    @Input({ required: true }) items: ActionBarItemNormalized[];
    isOpen = false;
    private justOpened = false;

    constructor(private abdropdownMenuService: ABDropdownMenuService) {}

    onGroupClick(): void {
        if (!this.isOpen) {
            this.justOpened = true;
            this.abdropdownMenuService.setOpenDropdown(this);
        } else {
            this.abdropdownMenuService.closeOpenDropdown();
        }
    }

    close(): void {
        this.isOpen = false;
    }

    open(): void {
        this.isOpen = true;
    }

    onOutsideClick(): void {
        if (this.justOpened) {
            // Clicking the dropdown button will open the dropdown, but the click event will also
            // be registered by the clickOutside directive, which will close the dropdown
            this.justOpened = false;
            return;
        }
        this.abdropdownMenuService.closeOpenDropdown();
    }
}
