import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { DrawerComponent } from '@progress/kendo-angular-layout';

@Component({
    selector: 'app-drawer',
    templateUrl: './drawer.component.html'
})
export class AppDrawerComponent implements OnChanges {
    @Input() showFilterButtons = true;
    @Input() showResetButton = false;
    @Input() showToggleButton = false;
    @Input() title = 'Filters';
    @Input() toggle = false;

    @Output() public search = new EventEmitter<any>();
    @Output() public clearFilter = new EventEmitter<any>();
    @Output() public resetOptions = new EventEmitter<any>();

    @ViewChild(DrawerComponent) public drawer: DrawerComponent;

    @HostListener('document:keydown.escape', ['$event']) onEscapePress(/*event: KeyboardEvent*/) {
        if (this.drawer.expanded) this.drawer.toggle();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.drawer && changes.toggle) {
            this.drawer.toggle();
        }
    }
}
