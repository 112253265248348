import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewMode } from '@common/models/view-mode';
import { DialogService } from '@common/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { DarkModeService } from './dark-mode.service';
import { I18nService } from './i18n.service';
import { NavigationService } from './navigation.service';
import { QueryService } from './query.service';
import { RememberStateService } from './remember-state.service';
import { SettingsService } from './settings.service';
import { ToastrNotificationService } from './toastr-notification.service';
import { UserService } from './user.service';

@Injectable()
export class CommonService {
    constructor(
        public toastrNotificationService: ToastrNotificationService,
        public translateService: TranslateService,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public userService: UserService,
        public dialogService: DialogService,
        public queryService: QueryService,
        public rememberStateService: RememberStateService,
        public http: HttpClient,
        public i18nService: I18nService,
        public darkModeService: DarkModeService,
        public settingsService: SettingsService,
        public injector: Injector,
        public navigationService: NavigationService
    ) {}

    isViewMode() {
        return this.activatedRoute.snapshot.data.mode === ViewMode.view;
    }

    isEditMode() {
        return !this.isViewMode();
    }

    getSanitizedUrl(): string {
        const url = this.router.url;
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        const mode = this.activatedRoute.snapshot.data.mode;

        if (id && mode) return url.replace(`/${mode}`, '').replace(`/${id}`, '');
        return url;
    }
}
