export class SettingKeyCodes {
    static readonly AppBackgroundImage = 'AppBackgroundImage';
    static readonly LoginBackgroundImage = 'LoginBackgroundImage';
    static readonly UserRegistration = 'UserRegistration';
    static readonly ForgetPassword = 'ForgetPassword';
    static readonly TermsAndConditions = 'TermsAndConditions';
    static readonly NoticeBoard = 'NoticeBoard';
    static readonly Chat = 'Chat';
    static readonly FAQ = 'FAQ';
    static readonly DocumentManagement = 'DocumentManagement';
    static readonly ShortCompanyName = 'ShortCompanyName';
    static readonly LongCompanyName = 'LongCompanyName';
    static readonly Logo = 'Logo';
    static readonly LanguageFlag = 'LanguageFlag';
    static readonly CertificateLogin = 'CertificateLogin';
    static readonly GoogleLogin = 'GoogleLogin';
    static readonly AppleLogin = 'AppleLogin';
    static readonly FacebookLogin = 'FacebookLogin';
    static readonly DateFormat = 'DateFormat';
    static readonly TimeFormat = 'TimeFormat';
    static readonly Currency = 'Currency';
    static readonly Grid = 'Grid';

    static readonly ImageSettings = [
        SettingKeyCodes.AppBackgroundImage,
        SettingKeyCodes.LoginBackgroundImage,
        SettingKeyCodes.Logo
    ];

    static readonly LoginSettings = [
        SettingKeyCodes.LanguageFlag,
        SettingKeyCodes.Logo,
        SettingKeyCodes.LongCompanyName
    ];
}
