import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[pill]'
})
export class PillDirective implements OnChanges {
    // Colors
    dark = '#222';
    light = '#fff';
    green = '#5DD879';
    darkgreen = '#2DBC4E';
    red = '#E56B77';
    darkred = '#D44B59';
    blue = '#59A9FF';
    orange = '#FD9A47';
    amber = '#FFC000';
    yellow = '#FFC107';
    grey = '#CED4DA';
    purple = '#BA55D3';
    black = '#000';

    // Formatting
    formatting = {
        maxHeight: '1.45em',
        display: 'block',
        lineHeight: '1.45em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 0.5em 0 0.5em',
        borderRadius: '4px',
        color: this.dark,
        fontWeight: 'bold',
        textAlign: 'center',
        textDecoration: 'none'
    };

    // Status, module & muted input
    @Input() pill: { status: string; module: string };

    el: ElementRef;

    constructor(el: ElementRef) {
        this.el = el;

        // Apply formatting
        for (const prop in this.formatting) {
            if (Object.prototype.hasOwnProperty.call(this.el.nativeElement.style, prop)) {
                this.el.nativeElement.style[prop] = this.formatting[prop];
            }
        }
    }

    ngOnChanges() {
        this.el.nativeElement.style.backgroundColor = this.pillColor(this.pill);
    }

    private textColor(color: string): void {
        this.el.nativeElement.style.color = color;
    }

    private pillColor(input: any): string {
        const status = input.status?.toUpperCase();
        const module = input.module;

        if (module === 'VesselVisit') {
            switch (status) {
                case 'ANN':
                    return this.blue;
                case 'ARR':
                    return this.green;
                case 'DEP':
                    return this.purple;
                case 'CAN':
                    return this.red;
            }
        }
        return this.grey;
    }
}
