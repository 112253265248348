<div
    class="group overflow-hidden"
    [ngStyle]="{ 'max-width.px': maxWidthPx }"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false">
    <div
        class="w-min whitespace-nowrap ease-linear"
        #innerDiv
        [ngStyle]="{
            'transition-duration': animationDuration + 'ms',
            transform: shouldAnimate() ? 'translateX(calc(-100% + ' + maxWidthPx + 'px))' : 'none'
        }">
        <span>{{ text }}</span>
    </div>
</div>
