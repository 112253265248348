import { Injectable } from '@angular/core';
import { ABDropdownMenuComponent } from './abdropdown-menu.component';

@Injectable({ providedIn: 'root' })
export class ABDropdownMenuService {
    private currentOpenDropdown: ABDropdownMenuComponent = null;

    public setOpenDropdown(dropdown: ABDropdownMenuComponent): void {
        if (this.currentOpenDropdown && this.currentOpenDropdown !== dropdown) {
            this.currentOpenDropdown.close();
        }
        this.currentOpenDropdown = dropdown;
        this.currentOpenDropdown.open();
    }

    public closeOpenDropdown(): void {
        if (this.currentOpenDropdown) {
            this.currentOpenDropdown.close();
            this.currentOpenDropdown = null;
        }
    }
}
